// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import * as looposUI from '../../../vendor/gems/loopos-ui/app/javascript/packs/loopos-ui.js'
import * as looposSettings from '../../../vendor/gems/loop-os-settings/app/javascript/packs/loopos-settings.js'

const application = Application.start()
const context1 = looposUI.controllerRequireContext
const context2 = require.context('../controllers', true, /_controller\.js$/)
const combinedContext = looposSettings.combineContexts(context1, context2, looposSettings.controllerRequireContext)
looposSettings.application.load(definitionsFromContext(combinedContext))

window.ourStimulus = application