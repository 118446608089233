import { Controller } from 'stimulus'
import { GridStack } from 'gridstack'

export default class extends Controller {
  static targets = ['frame']

  connect() {
    const opts = {
      float: true,
      sizeToContent: true,
      disableResize: true,
      cellHeight: 'auto',
      staticGrid: true,
    }

    this.grid = GridStack.init(opts, this.element)

    this.grid.on('change', (event, items) => {
      this.adjustAllHeights()
      this.grid.compact('compact', true)
    })

    this.grid.on('resizestop', (event, el) => {
      this.adjustHeight(el)
      this.grid.compact('compact', true)
    })

    this.adjustAllHeights()

    // Add event listener for Turbo Frame load
    this.frameTargets.forEach((frame) => {
      frame.addEventListener('turbo:frame-load', (el) => {
        console.log(el.target.parentElement.parentElement)
        this.adjustAllHeights(el.target.parentElement.parentElement)
        this.grid.resizeToContent(el.target.parentElement.parentElement)
        this.grid.compact('compact', true)
      })
    })
  }

  adjustHeight(el) {
    const content = el.querySelector('.grid-stack-item-content')
    if (content) {
      // el.style.height = 'auto' // Reset height to auto to recalculate
      // el.style.height = content.scrollHeight + 'px' // Set height based on content
    }
    this.grid.resizeToContent(el)
  }

  adjustAllHeights() {
    this.grid.engine.nodes.forEach((node) => {
      this.adjustHeight(node.el)
    })
  }

  disconnect() {
    this.grid.destroy()
  }
}
