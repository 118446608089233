import * as React from 'react'
import { ChangeEvent, FocusEvent, useEffect, useRef } from 'react'
import {
  ariaDescribedByIds,
  BaseInputTemplateProps,
  examplesId,
  FormContextType,
  getInputProps,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils'

export default function BaseInputTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  value,
  onChange,
  onChangeOverride,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  rawErrors = [],
  children,
  extraProps,
}: BaseInputTemplateProps<T, S, F>) {
  const inputProps = {
    ...extraProps,
    ...getInputProps<T, S, F>(schema, type, options),
  }
  const _onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onChange(value === '' ? options.emptyValue : value)
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onBlur(id, value)
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onFocus(id, value)

  let inputClass = `
    border rounded-lg h-8 px-2.5 py-2 border-[#DEE2E6] focus:border-black  w-full bg-white primary-14-regular 
    ${rawErrors.length > 0 ? 'border-red-500' : 'border-muted-foreground'}
  `

  if (schema && schema['format'] === 'data-url'){
    inputClass = "upload_wrapper"
  }

  const previewId = `resource_${id}_preview`
  const inputField = useRef(null)
  const observeElement = (element, property, callback, delay = 0) => {
    let elementPrototype = Object.getPrototypeOf(element)
    if (elementPrototype.hasOwnProperty(property)) {
      let descriptor = Object.getOwnPropertyDescriptor(elementPrototype, property)
      Object.defineProperty(element, property, {
        get: function () {
          return descriptor.get.apply(this, arguments)
        },
        set: function () {
          let oldValue = this[property]
          descriptor.set.apply(this, arguments)
          let newValue = this[property]
          if (typeof callback == 'function') {
            setTimeout(callback.bind(this, oldValue, newValue), delay)
          }
          return newValue
        },
      })
    }
  }

  const canBindEvents = () => {
    return inputProps.type == 'string' || inputProps.type == 'text'
  }

  const dropkiqAttrs = () => {
    if (canBindEvents()) {
      return { 'data-controller': 'dropkiq', 'data-preview-id': previewId }
    }

    return {}
  }

  useEffect(() => {
    if (inputField.current && canBindEvents()) {
      observeElement(inputField.current, 'value', function (oldValue, newValue) {
        onChange(newValue)
      })
    }
  }, [inputField.current])

  return (
    <>
      <input
        ref={inputField}
        id={id}
        name={id}
        type={type}
        placeholder={placeholder}
        autoFocus={autofocus}
        required={required}
        {...dropkiqAttrs()}
        disabled={disabled}
        readOnly={readonly}
        className={inputClass}
        autoComplete={'new-password'}
        list={schema.examples ? examplesId<T>(id) : undefined}
        {...inputProps}
        value={value || value === 0 ? value : ''}
        onChange={onChangeOverride || _onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        aria-describedby={ariaDescribedByIds<T>(id, !!schema.examples)}
      />
      {children}
      {Array.isArray(schema.examples) ? (
        <datalist id={examplesId<T>(id)}>
          {(schema.examples as string[])
            .concat(schema.default && !schema.examples.includes(schema.default) ? ([schema.default] as string[]) : [])
            .map((example: any) => {
              return <option key={example} value={example} />
            })}
        </datalist>
      ) : null}
      <div id={previewId} className={'dropkiq-preview'} style={{ visibility: 'hidden', display: 'none' }}></div>
    </>
  )
}
