/* eslint-disable no-console */

import Axios, { AxiosError, AxiosResponse } from 'axios';

const API_URL = '';
const axios = Axios.create({
  baseURL: '/api/v1/',
  timeout: 150000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
  },
});

export const CoreAPI = (authToken: string | null, baseUrl: string = '/api/v1') => {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common.Authorization = authToken;

  return {
    parseSchema: (schema: Object, url: string = '/loop-os-settings/parse_schema', csrf: string) =>
      Axios.post(url, {schema: schema}, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
          'X-CSRF-Token': csrf,
          'Authorization': authToken,
        },
        timeout: 150000,
      })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => {
          console.error(error);
          return [];
        }),
    fetchSchema: (url: string) =>
      axios
        .get(url)
        .then((response: AxiosResponse) => response.data)
        .catch(() => []),
    update: (data: any, url: string) =>
      axios
        .patch(url, {
          ...data,
        })
        .then((response: AxiosResponse) => {
          if (response.data.url_redirect) {
            // onboarding form to create partner use case
            window.location.replace( response.data.url_redirect);
          } else {
            response.data;
          }
        })
        .catch((error: AxiosError) => {
          throw error;
        }),
    settingsAttachments: (formData: any, csrf: string, url: string = '/loop-os-settings/settings_attachments') =>
      Axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'X-CSRF-Token': csrf,
            'Authorization': authToken,
          }
        })
        .then((response: AxiosResponse) => response.data)
        .catch(() => []),
    deleteSettingsAttachments: (id: any, csrf: string) =>
        Axios
          .delete(`/loop-os-settings/settings_attachments/${id}`,{
            headers: {
              'Accept': 'application/json',
              'X-CSRF-Token': csrf,
              'Authorization': authToken,
            }
          })
          .catch(() => []),
    markAttachmentsProcessed: (data: any, csrf: string) =>
      Axios
        .put('/loop-os-settings/settings_attachments/mark_processed',
          data, {
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': csrf,
            'Authorization': authToken,
          }
        })
        .then((response: AxiosResponse) => response.data)
        .catch(() => []),
    updateSettingsAttachment: (id: any, data: any, csrf: string) =>
      Axios
        .put(`/loop-os-settings/settings_attachments/${id}`,
          data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'X-CSRF-Token': csrf,
            'Authorization': authToken,
          }
        })
        .then((response: AxiosResponse) => response.data)
        .catch(() => []),
  };
};
