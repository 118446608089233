// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';

import 'channels';
import '@hotwired/turbo-rails';
import 'initializers';
import 'controllers';
import 'flowbite/dist/flowbite.turbo.js';
import 'flowbite/dist/datepicker.turbo.js';
import 'urijs';
import '@rails/request.js';
import $ from 'jquery';
import 'sortablejs';

import * as looposUI from "../../../vendor/gems/loopos-ui/app/javascript/packs/loopos-ui.js";
import * as looposSettings from '../../../vendor/gems/loop-os-settings/app/javascript/packs/loopos-settings.js';

const context1 = looposUI.componentRequireContext;
const context2 = require.context("components", true);

const combinedContext = looposUI.combineContexts(context1, context2, looposSettings.componentRequireContext);
looposUI.ReactRailsUJS.useContext(combinedContext);
looposUI.mountComponents();


window.$ = $;

Rails.start();
ActiveStorage.start();
// Support component names relative to this directory:
// var componentRequireContext = require.context('components', true);

// var ReactRailsUJS = require('react_ujs');
// ReactRailsUJS.useContext(componentRequireContext);

// ReactRailsUJS.handleEvent("turbo:load", ReactRailsUJS.handleMount);
// ReactRailsUJS.handleEvent("turbo:before-render", ReactRailsUJS.handleUnmount);

// ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount)
// ReactRailsUJS.handleEvent('turbo:frame-render', ReactRailsUJS.handleUnmount)

// Adapted from https://github.com/reactjs/react-rails/issues/1113
import React from 'react';
import ReactDOM from 'react-dom';

document.addEventListener('DOMContentLoaded', () => {
  const findComponents = (childNodes, testFn, nodes = []) => {

    for (let child of childNodes) {
      if (child.childNodes.length > 0) {
        nodes = findComponents(child.childNodes, testFn, nodes);
      } else if (testFn(child)) {
        nodes = nodes.concat([child]);
      }
    }
    return nodes;
  };

  const mountComponents = (nodes) => {
    for (let child of nodes) {
      const className = child.getAttribute(ReactRailsUJS.CLASS_NAME_ATTR);
      if (className) {
        // Taken from ReastRailsUJS as is.
        const constructor = ReactRailsUJS.getConstructor(className);
        const propsJson = (child).getAttribute(ReactRailsUJS.PROPS_ATTR);
        const props = propsJson && JSON.parse(propsJson);

        // Improvement:
        // Was this component already rendered? Just hydrate it with the props coming in.
        // This is currently acceptable since all our components are expected to be reset
        // on page navigation.
        const component = React.createElement(constructor, props);
        ReactDOM.render(component, child);
      }
    }
  };

  const callback = function (mutationsList, observer) {
    const start = performance.now();
    // console.log("ReactRails: Mutation callback started...", mutationsList)

    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        if (mutation.addedNodes.length > 0) {
          const mountableNodes = findComponents(mutation.addedNodes, (child) => {
            return !!child.dataset?.reactClass;
          });

          mountComponents(mountableNodes);
        }
      }
    }

    // console.log("ReactRails: Mutation callback complete.", performance.now() - start)
  };

  const observer = new MutationObserver(callback);

  // console.log("ReactRails: Start mutation observer...")
  observer.observe(document, { childList: true, subtree: true });
});
